import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commaNumber from 'comma-number';

import { pointActions } from '../../../../actions';

class Point extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.point;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS' && data.result) {

      let point = 0;
      data.result.pointList.forEach(el => {
        point += el.restPoint;
      });

      if (prevState.point !== point) {
        return {
          point,
        };
      }
    }
    
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      point: 0,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');
    if (token !== null) {
      this.props.actions.pointActions.request({ token });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.point;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { point } = this.state;
    
    return (
      <span>{commaNumber(point)}</span>
    );
  }
}

const mapStateToProps = (state) => {
  const { point } = state;

  return {
    point,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      pointActions: bindActionCreators(pointActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Point);